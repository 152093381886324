
import Uploader from "vue-upload-component";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: { Uploader } })
export default class VFileUploader extends Vue {
  @Prop({ default: "*" })
  readonly extensions!: string;

  @Prop()
  readonly value!: any;

  @Prop({ default: "*" })
  readonly accept!: string;

  @Prop({ default: false })
  readonly multiple!: boolean;

  @Prop()
  readonly uploaderClass!: string;

  private files: any[] = [];

  openFileBrowser() {
    (this.$children[0].$children[0] as any).$el.click();
  }

  inputFile(newFile: any, oldFile: any) {
    if (
      newFile &&
      (!oldFile || newFile.file !== oldFile.file) &&
      !newFile.active
    ) {
      this.$emit("change", this.multiple ? this.files : this.files[0]);
      this.$emit("input", this.multiple ? this.files : this.files[0]);
    }
  }

  inputFilter(newFile: any, oldFile: any, prevent: any) {
    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      newFile.url = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.url = URL.createObjectURL(newFile.file);
      }
    }
  }
}
